@layer components {
  
  .m-dropdown {
  }
  
  .m-dropdown__menu {
    @apply hidden;
  }
    
  .m-dropdown--visible .m-dropdown__menu {
    @apply flex flex-col absolute z-30;
    top: 100%;
    right: 0;
  }
  
}