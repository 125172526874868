@layer components {
  .header-navigation {
    margin: 0 -1rem;
  }
  .header-navigation a {
    @apply text-current;
  }
  
  
  /** links **/
  .header-navigation li > a {
    @apply block whitespace-no-wrap text-sm no-underline;
  }
  .header-navigation li > a:hover,
  .header-navigation li > a:active {
    @apply text-current underline;
  }
  
    
  /** top level navigation **/
  .header-navigation > ul {
    @apply flex;
  }
  
  .header-navigation > ul > li {
    @apply relative;
  }
  .header-navigation > ul > li:hover {
    @apply bg-base-accent;
  }
  .header-navigation > ul > li > a {
    @apply p-4 font-semibold uppercase no-underline;
  }
  
  
  /** second level navigation **/
  .header-navigation > ul > li > ul {
    @apply hidden leading-loose;
  }
  .header-navigation > ul > li > ul > li > a {
    @apply pl-10 pr-20;
  }
  .header-navigation > ul > li:hover > ul {
    @apply flex flex-col absolute bg-base-accent pt-4 pb-8 z-30;
    top: 100%;
    left: 0;
    min-width: 120%;
  }
  
  /** categories navigation **/
  
  .header-navigation > .header-navigation__categories {
    @apply flex-grow justify-between;
  }
    
  .header-navigation > .header-navigation__menu-items {
    margin-left: 5%;
  }
  
}